import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as assetTypes from '../components/AssetClass/assetTypes';
import * as applicationActions from '../actions/applicationActions';
import * as registrationActions from '../actions/registrationActions';
import * as accountTypeActions from '../actions/accountTypeActions';
import * as assetClassActions from '../actions/assetClassActions';
import * as identityActions from '../actions/identityActions';
import * as addressActions from '../actions/addressActions';
import * as tradingProfileActions from '../actions/tradingProfileActions';
import * as userActions from '../actions/userActions';
import * as financialActions from '../actions/financialActions';
import * as RouteNavigator from './RouteNavigator';
import ApprovalStatus from '../components/ApprovalStatus/ApprovalStatus';
import optimizeHelper from '../lib/optimizeHelper';
import signOutUrl from '../lib/signOutUrl';
import Config from '../Config';
import carmaAccountCreationFailed from '../lib/containerHelpers/approvalStatus/approvalStatusHelper';
import pushToAnalytics from '../lib/analytics';
import { aopAnalyticsSteps } from '../lib/analyticsHelper';
import ApprovalEntity from '../components/ApprovalEntity/ApprovalEntity';

const config = new Config();

function redirectToDepositFunds() {
  RouteNavigator.push('/deposit-funds');
}

const redirectToAccountType = () => {
  RouteNavigator.push('/account-type');
};

const redirectToTsLearn = () => {
  window.location = config.tradestationLearnUrl;
};

export class ApprovalStatusContainer extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.startNewApplication = this.startNewApplication.bind(this);
    this.finishApplication = this.finishApplication.bind(this);
  }

  componentWillMount() {
    const { application } = this.props;
    if (!application || !application.accountsCreated) {
      this.props.actions.fetchApplication(this.props.applicationId, this.props.authToken)
        .then((action) => {
          if (action.application
            && action.application.accountsCreated
            && carmaAccountCreationFailed(application.accountsCreated)) redirectToDepositFunds();
        });
    } else if (application &&
      application.accountsCreated &&
      carmaAccountCreationFailed(application.accountsCreated)) redirectToDepositFunds();
  }

  componentDidMount() {
    const {
      actions,
      applicationId,
      authToken,
      registration,
      accountType,
      assetClass,
      identity,
      address,
      tradingProfile,
      financial,
    } = this.props;

    if (!registration || !accountType || !assetClass || !identity || !address || !tradingProfile || !financial) {
      Promise.all([
        actions.fetchRegistration(applicationId, authToken),
        actions.fetchAccountType(applicationId, authToken),
        actions.fetchAssetClass(applicationId, authToken),
        actions.fetchIdentity(applicationId, authToken),
        actions.fetchAddress(applicationId, authToken),
        actions.fetchTradingProfile(applicationId, authToken),
        actions.fetchFinancial(applicationId, authToken),
      ]).then(() => {
        pushToAnalytics(
          aopAnalyticsSteps.THANK_YOU.name,
          { applicationId,
            authToken,
            registration,
            accountType,
            assetClass,
            address,
            identity,
            tradingProfile,
            financial,
          },
        );
      });
    } else {
      pushToAnalytics(
        aopAnalyticsSteps.THANK_YOU.name,
        {
          applicationId,
          authToken,
          registration,
          accountType,
          assetClass,
          address,
          identity,
          tradingProfile,
          financial,
        },
      );
    }
    optimizeHelper.notify();
  }

  startNewApplication() {
    const { authToken, userId } = this.props;
    this.props.actions.startApplication(userId, authToken)
      .then(redirectToAccountType)
      .catch(() => {
        this.props.actions.userLogout(this.props.authToken)
        .then(() => {
          window.location = signOutUrl('/logout.html?manual');
        });
      });
  }

  finishApplication() {
    this.props.actions.userLogout(this.props.authToken).then(redirectToTsLearn);
  }

  render() {
    if (this.props && this.props.accountType) {
      const { accountType } = this.props.accountType;
      if (accountType.includes('entity')) {
        return (
          <ApprovalEntity
            {...this.props}
            startNewApplication={this.startNewApplication}
            finishApplication={this.finishApplication}
          />
        );
      }
    }

    return (
      <ApprovalStatus
        {...this.props}
        startNewApplication={this.startNewApplication}
        finishApplication={this.finishApplication}
      />
    );
  }
}

ApprovalStatusContainer.propTypes = {
  actions: PropTypes.shape({
    fetchApplication: PropTypes.func.isRequired,
    fetchRegistration: PropTypes.func.isRequired,
    fetchAccountType: PropTypes.func.isRequired,
    fetchAssetClass: PropTypes.func.isRequired,
    fetchIdentity: PropTypes.func.isRequired,
    startApplication: PropTypes.func.isRequired,
    fetchAddress: PropTypes.func.isRequired,
    fetchTradingProfile: PropTypes.func.isRequired,
    fetchFinancial: PropTypes.func.isRequired,
    userLogout: PropTypes.func.isRequired,
  }).isRequired,
  applicationId: PropTypes.string,
  accountType: PropTypes.shape(),
  authToken: PropTypes.string.isRequired,
  application: PropTypes.shape(),
  registration: PropTypes.shape(),
  assetClass: PropTypes.shape(),
  identity: PropTypes.shape(),
  userId: PropTypes.number.isRequired,
  address: PropTypes.shape(),
  tradingProfile: PropTypes.shape(),
  financial: PropTypes.shape(),
};

export function mapStateToInitialValues(state) {
  let canTradeCrypto = false;
  if (state.accountType && state.accountType.assetTypes) {
    if (state.accountType.assetTypes.includes(assetTypes.ASSET_CLASS_TYPES_CRYPTO)) {
      const cryptoSelected = state.accountType.assetTypes.includes(assetTypes.ASSET_CLASS_TYPES_CRYPTO);
      canTradeCrypto = config.cryptoEnable && cryptoSelected;
    }
  }

  return {
    canTradeCrypto,
  };
}

function mapStateToProps(state) {
  const initialValues = mapStateToInitialValues(state);

  return {
    application: state.application,
    applicationId: state.applicationId,
    authToken: state.authToken,
    userId: state.userId,
    canTradeCrypto: !!initialValues.canTradeCrypto,
    registration: state.registration,
    accountType: state.accountType,
    assetClass: state.assetClass,
    identity: state.identity,
    address: state.address,
    tradingProfile: state.tradingProfile,
    financial: state.financial,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({},
      applicationActions,
      registrationActions,
      accountTypeActions,
      assetClassActions,
      identityActions,
      addressActions,
      tradingProfileActions,
      userActions,
      financialActions,
    ), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalStatusContainer);
