import React from 'react';
import PropTypes from 'prop-types';

const ApprovalStatus = (props) => {
  const {
    startNewApplication,
    finishApplication,
  } = props;

  return (
    <div>
      <section className="flow-text">
        <div className="container">
          <div className="row">
            <div className="col col-md-12 col-lg-8 col-lg-offset-2">
              <h1 className="h1_secondary text-center">Thank you for choosing</h1>
            </div>
          </div>
          <div>
            <div className="row">
              <div className="col col-md-12 col-lg-8 col-lg-offset-2 text-center">
                <br /><img src="images/tradestation.png" alt="" className="ts-logo-thankyou-page" /><br /><br />
                <p className="p_leadForm">
                  Your account application is currently being reviewed. You will receive an
                  email notification as soon as your account is approved including funding
                  instructions and other important information.
                </p>
              </div>
            </div>
            <div className="row pending-btn-group">
              <div className="col col-md-6 col-lg-6 col-sm-12 border-intro text-center">
                <button
                  type="submit"
                  id="next_btn"
                  className="btn btn-raised btn-default btn-left do_capitalize ty-btn"
                  onClick={finishApplication}
                >
                  I&apos;m Finished
                </button>
              </div>
              <div className="col col-md-6 col-lg-6 col-sm-12 text-center">
                <button
                  type="submit"
                  id="next_btn"
                  className="btn btn-raised btn-white btn-right do_capitalize ty-btn"
                  onClick={startNewApplication}
                >
                  Add Another Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

ApprovalStatus.propTypes = {
  startNewApplication: PropTypes.func.isRequired,
  finishApplication: PropTypes.func.isRequired,
};

export default ApprovalStatus;
