import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Table } from 'react-bootstrap';

class SupportedSymbolsModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleHide = this.handleHide.bind(this);
  }

  handleHide() {
    this.props.handleSupportedSymbolsModalToggleOff();
  }

  render() {
    return (
      <Modal
        className="supportedSymbolsModalMargin"
        show={this.props.showSupportedSymbolsModal}
        onHide={this.handleHide}
        dialogClassName="supportedSymbolsModal"
      >
        <Modal.Header closeButton>
          <h2 className="text-center supportedSymbolsModalHeader">Supported Symbols</h2>
        </Modal.Header>
        <Modal.Body className="supportedSymbolsModalBody">
          <Table className="supportedSymbolsModalTable" bordered="true">
            <tbody className="supportedSymbolsModalTableBody">
              <tr>
                <td>!</td>
                <td>(</td>
                <td>.</td>
                <td>&gt;</td>
                <td>_</td>
              </tr>
              <tr>
                <td>&quot;</td>
                <td>)</td>
                <td>/</td>
                <td>?</td>
                <td>`</td>
              </tr>
              <tr>
                <td>#</td>
                <td>*</td>
                <td>@</td>
                <td>&#123;</td>
                <td>$</td>
              </tr>
              <tr>
                <td>+</td>
                <td>;</td>
                <td>[</td>
                <td>&#125;</td>
                <td>%</td>
              </tr>
              <tr>
                <td>,</td>
                <td>&lt;</td>
                <td>]</td>
                <td>|</td>
                <td>&amp;</td>
              </tr>
              <tr>
                <td>-</td>
                <td>=</td>
                <td>^</td>
                <td>~</td>
                <td> </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    );
  }
}

SupportedSymbolsModal.propTypes = {
  showSupportedSymbolsModal: PropTypes.bool,
  handleSupportedSymbolsModalToggleOff: PropTypes.func,
};

export default SupportedSymbolsModal;
