import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const AcatFrame = ({ acatAppUrl, authToken }) => {
  const [iframeElement, setIframeElement] = useState(undefined);
  const [iframeHeight, setIframeHeight] = useState('120%');
  useEffect(() => {
    window.onmessage =
      ('message',
      (event) => {
        const eventData = JSON.parse(event.data);
        if (!(eventData && eventData.messageType)) return;
        if (eventData.messageType === 'ready') {
          postAccessTokenToAcatIframe();
        }
        if (eventData.messageType === 'resize') {
          setIframeHeight(eventData.height);
        }
        if (eventData.messageType === 'routeChange') {
          setIframeHeight('120%');
        }
      });
    const iframe = document.getElementById('acatFrame');
    setIframeElement(iframe);
    iframe.src = acatAppUrl;
    return () => {
      if (!iframeElement) return;
      iframeElement.removeEventListener('ready');
    };
  }, []);

  function postAccessTokenToAcatIframe() {
    const data = { accessToken: authToken };
    const iframe = document.getElementById('acatFrame');
    if (iframe) {
      const iframeDocument =
        iframe.contentWindow ||
        iframe.contentDocument ||
        iframe.contentDocument;
      iframeDocument.postMessage(data, '*');
    }
  }

  return (
    <div>
      <div
        id="acat-frame-container"
        className="col col-md-12 col-lg-10 h-full col-lg-offset-1 text-center"
      >
        {authToken && (
          <iframe
            id="acatFrame"
            title="Acat iFrame"
            width="100%"
            height={iframeHeight}
            style={{ overflow: 'hidden' }}
          />
        )}
      </div>
    </div>
  );
};

AcatFrame.propTypes = {
  acatAppUrl: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
};

export default AcatFrame;
