import { formValueSelector } from 'redux-form';
import {
  findContactById, findContactByType, findCountry, findRegion, isContactInCountryRegion,
  isTselCountry, shouldRemoveRegion, mapContact,
} from '../../../containers/contactSearchAndFormat';
import * as constants from '../../constants';
import { mapRegionOptions, filterAndSortAllowedCountriesAndRegions } from '../../sortFilterMapCountriesRegions';


function mapResidentialAddress(address, countries) {
  const country = findCountry(address.residentialCountry, countries);
  const region = findRegion(address.residentialCountry, address.residentialRegion, countries);
  return {
    dontContact: address.doNotContact,
    sameAddress: !address.sameMailingAddress,
    copyFromPrimary: address.sameSecondaryResidential,
    country: country ? country.countryCode : '',
    countryName: country ? country.country : '',
    address1: address.residentialAddress1 ? address.residentialAddress1 : '',
    address2: address.residentialAddress2 ? address.residentialAddress2 : '',
    city: address.residentialCity ? address.residentialCity : '',
    region: region ? region.regionCode : '',
    regionName: region ? region.region : '',
    zip: address.residentialZip ? address.residentialZip : '',
  };
}

function mapMailingAddress(address, countries) {
  const country = findCountry(address.mailingCountry, countries);
  const region = findRegion(address.mailingCountry, address.mailingRegion, countries);
  return {
    copyFromPrimary: address.sameSecondaryMailing,
    country: country ? country.countryCode : '',
    countryName: country ? country.country : '',
    address1: address.mailingAddress1 ? address.mailingAddress1 : '',
    address2: address.mailingAddress2 ? address.mailingAddress2 : '',
    city: address.mailingCity ? address.mailingCity : '',
    region: region ? region.regionCode : '',
    regionName: region ? region.region : '',
    zip: address.mailingZip ? address.mailingZip : '',
  };
}

function mapAddress(address, contact, countries) {
  return {
    id: address.id,
    sort: contact.type === 'primary' ? 0 : contact.id,
    residential: mapResidentialAddress(address, countries),
    mailing: mapMailingAddress(address, countries),
    contact: mapContact(contact, countries),
  };
}

function newAddress(contact, countryOfResidence, regionOfResidence, countries) {
  return {
    id: null,
    sort: contact && contact.type === 'primary' ? 0 : 1,
    residential: {
      dontContact: false,
      sameAddress: false,
      copyFromPrimary: true,
      country: countryOfResidence ? countryOfResidence.countryCode : '',
      countryName: countryOfResidence ? countryOfResidence.country : '',
      address1: '',
      address2: '',
      city: '',
      region: regionOfResidence ? regionOfResidence.regionCode : '',
      regionName: regionOfResidence ? regionOfResidence.region : '',
      zip: '',
    },
    mailing: {
      copyFromPrimary: true,
      country: countryOfResidence && countryOfResidence === 'US' ? countryOfResidence.countryCode : '',
      countryName: countryOfResidence && countryOfResidence === 'US' ? countryOfResidence.country : '',
      address1: '',
      address2: '',
      city: '',
      region: regionOfResidence ? regionOfResidence.regionCode : '',
      regionName: regionOfResidence ? regionOfResidence.region : '',
      zip: '',
    },
    contact: mapContact(contact, countries),
  };
}

export function mapStateToInitialValues(state, isJointApplication, isCryptoApplication) {
  const addresses = [];

  if (state && state.address && state.address.length > 0) {
    state.address.forEach((address) => {
      if (state && state.contact && state.contact.length > 0) {
        const contact = findContactById(address.contactId, state.contact);
        addresses.push(mapAddress(address, contact, state.countries));
      }
    });
  }

  if (state && state.contact && state.contact.length > 0) {
    const contactPrimary = findContactByType('primary', state.contact);
    const contactJoint = findContactByType('joint', state.contact);
    const country = findCountry(contactPrimary.countryOfResidence, state.countries);
    const region = findRegion(country ? country.countryCode : null, contactPrimary.regionOfResidence, state.countries);

    if (addresses.length < 1) {
      addresses.push(newAddress(contactPrimary, country, region, state.countries));
    }

    const meetsCryptoJointCriteria = (
      isCryptoApplication &&
      isJointApplication &&
      contactPrimary.regionOfResidence !== contactJoint.regionOfResidence
    );

    if (addresses.length < 2 && isJointApplication) {
      if (meetsCryptoJointCriteria) {
        const regionJoint =
          findRegion(country ? country.countryCode : null, contactJoint.regionOfResidence, state.countries);
        const jointAddress = newAddress(contactJoint, country, regionJoint, state.countries);
        jointAddress.residential.copyFromPrimary = false;
        addresses.push(jointAddress);
      } else addresses.push(newAddress(contactJoint, country, region, state.countries));
    }
  }

  addresses.sort((a, b) => {
    return a.sort - b.sort;
  });

  return { addresses };
}

export function setCountriesRegions(state) {
  return {
    allowedCountries: filterAndSortAllowedCountriesAndRegions(state.countries),
    // eslint-disable-next-line max-len
    removeShandong: shouldRemoveRegion(state.accountType, state.contact, constants.PRIMARY, constants.CHINA, constants.SHANDONG),
    // eslint-disable-next-line max-len
    isChinaShandong: isContactInCountryRegion(state.accountType, state.contact, constants.PRIMARY, constants.CHINA, constants.SHANDONG),
  };
}

export function props(state, isJointApplication, isCryptoApplication) {
  const countryMailing = [];
  const countryResidential = [];
  const regionsMailing = [];
  const regionsResidential = [];
  const showCopyFromPrimary = [];
  const showDoNotContact = [];
  const showMailingAddress = [];
  const showNonProAddressToolTip = [false, false];

  const initialValues = mapStateToInitialValues(state, isJointApplication, isCryptoApplication);

  const addressExists = (state && state.address && state.address.length > 0);

  const selector = formValueSelector('personal-info');
  const addresses = selector(state, 'addresses');

  const { allowedCountries, isChinaShandong } = setCountriesRegions(state);

  if (addresses && addresses.length > 0) {
    for (let i = 0; i < addresses.length; i += i + 1) {
      countryMailing[i] = addresses[i].mailing.country;
      countryResidential[i] = addresses[i].residential.country;
      regionsMailing[i] = mapRegionOptions(state.countries, addresses[i].mailing.country, true);
      regionsResidential[i] = mapRegionOptions(state.countries, addresses[i].residential.country, true);
      showMailingAddress[i] = addresses[i].residential.sameAddress;
      showNonProAddressToolTip[i] = !initialValues.isEntityApplication;
      showDoNotContact[i] = isTselCountry(state.countries, addresses[i].residential.country);
      showCopyFromPrimary[i] = {
        residential: !addresses[i].residential.copyFromPrimary,
        mailing: !addresses[i].mailing.copyFromPrimary,
      };
    }
  }

  const isDomestic = addresses && addresses.length > 0 && addresses[0].residential.country === 'US';

  return {
    initialValues,

    addresses: addresses || [],
    addressExists: !!addressExists,
    countries: allowedCountries,
    countryMailing,
    countryResidential,
    isChinaShandong,
    isDomestic,
    regionsMailing,
    regionsResidential,
    showCopyFromPrimary,
    showDoNotContact,
    showMailingAddress,
    showNonProAddressToolTip,
  };
}
