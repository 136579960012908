import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Config from '../Config';
import { specialCharsPattern, nonPrintableCharsPattern } from '../lib/constants';

const config = new Config();

/**
 * Function that check input, transform it and replace chars found
 * @param {String} value
 * @returns new value transformed
 */
export function removeNonPrintableChars(value) {
  if (value && typeof value === 'string' && value.replace) {
    let newValue = value.replace(nonPrintableCharsPattern, '');
    newValue = newValue.replace(/(\\)+/g, '');
    return newValue;
  }
  return value;
}

/**
 * Function that check the input of the address fields, transform it and replace chars found
 * @param {String} value
 * @returns new value transformed
 */
export function removeNonAllowedSpecialChars(value) {
  if (value && typeof value === 'string' && value.replace) {
    let newValue = value.replace(specialCharsPattern, '');
    newValue = newValue.replace(/(\\)+/g, '');
    return newValue;
  }
  return value;
}

export function handleOnValueChanged(value, onMasking, active, submitting, allowSpecialCharacters) {
  let result =
    allowSpecialCharacters && config.physicalAddressTransform
      ? removeNonAllowedSpecialChars(value)
      : removeNonPrintableChars(value);
  if ((!active || submitting) && onMasking) {
    result = onMasking(result, true);
  }
  return result;
}

export const renderField = (props) => {
  const {
    className,
    label,
    labelMobile,
    input,
    maxLength,
    meta: { error, touched, active, submitting },
    id,
    tooltip,
    type = 'text',
    readOnly = false,
    onMasking,
    disabled,
    allowSpecialCharacters,
    inputFieldType,
  } = props;

  const normalStyle = 'form-group label-floating';
  const errorStyle = 'form-group label-floating has-error';

  function renderFieldOnValueChanged(value) {
    return handleOnValueChanged(value, onMasking, active, submitting, allowSpecialCharacters);
  }

  function handleOnFocus(e) {
    if (onMasking) {
      const val = input.value;
      e.target.value = val;
      input.onFocus(e);
    }
    e.target.select();
  }

  function handleOnBlur(e) {
    const val = input.value;
    input.onBlur(e);
    if (onMasking) {
      e.target.value = onMasking(val, true);
      e.preventDefault();
    }
  }

  return (
    <div className={touched && error && error !== 'Required' ? errorStyle : normalStyle}>
      <label className="control-label" htmlFor={input.name}>
        <span className={labelMobile ? 'hidden-xs hidden-sm visible-lg visible-md' : ''}>{label}</span>
        {labelMobile && <span className="hidden-lg hidden-md visible-xs visible-sm">{labelMobile}</span>}
      </label>
      <div className={tooltip && 'input-group'}>
        {inputFieldType !== 'textarea' &&
          <input
            {...input}
            type={type}
            id={id || input.name}
            name={input.name}
            className={className}
            maxLength={maxLength}
            readOnly={readOnly}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            value={renderFieldOnValueChanged(input.value)}
            disabled={disabled}
            /* eslint-disable-next-line */
            allowSpecialCharacters={allowSpecialCharacters}
          />
        }
        {inputFieldType === 'textarea' && // textarea element is used for multi-line text input
          <textarea
            {...input}
            type={type}
            id={id || input.name}
            name={input.name}
            className={className}
            maxLength={maxLength}
            readOnly={readOnly}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            value={renderFieldOnValueChanged(input.value)}
            disabled={disabled}
            /* eslint-disable-next-line */
            allowSpecialCharacters={allowSpecialCharacters}
            rows="3"
          />
        }
        {tooltip &&
          <span className="input-group-addon" data-tip data-for={input.name}>
            <i className="material-icons">help</i>
          </span>
        }
      </div>
      {touched && error && <div className="messages"><p className="help-block error">{error}</p></div>}
    </div>
  );
};

renderField.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape(),
  label: PropTypes.string,
  labelMobile: PropTypes.string,
  maxLength: PropTypes.number,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  id: PropTypes.string,
  tooltip: PropTypes.bool,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  onMasking: PropTypes.func,
  disabled: PropTypes.bool,
  allowSpecialCharacters: PropTypes.bool,
  inputFieldType: PropTypes.string,
};

const TextBox = ({
  className,
  label,
  labelMobile,
  name,
  maxLength,
  tooltip,
  type,
  readOnly,
  normalize,
  id,
  onMasking,
  disabled,
  allowSpecialCharacters,
  inputFieldType,
  onChange,
}) => {
  return (
    <Field
      type={type}
      id={id}
      name={name}
      component={renderField}
      className={className}
      label={label}
      labelMobile={labelMobile}
      maxLength={maxLength}
      tooltip={tooltip}
      readOnly={readOnly}
      normalize={normalize}
      onMasking={onMasking}
      disabled={disabled}
      allowSpecialCharacters={allowSpecialCharacters}
      inputFieldType={inputFieldType}
      onChange={onChange}
    />
  );
};

TextBox.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  labelMobile: PropTypes.string,
  maxLength: PropTypes.number,
  tooltip: PropTypes.bool,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  normalize: PropTypes.func,
  onMasking: PropTypes.func,
  disabled: PropTypes.bool,
  allowSpecialCharacters: PropTypes.bool,
  inputFieldType: PropTypes.string,
  onChange: PropTypes.func,
};

export default TextBox;
