import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Config from '../../Config';
import InlineFundingNavigation from '../InlineFundingNavigation';
import LinkAccountModal from '../InlineFunds/LinkAccountModal';

const config = new Config();

const Funding = (props) => {
  const {
    fundingUrl,
    onBack,
    shouldDisplayLinkingAccountHelpButton,
    title,
    authToken,
  } = props;
  const [isShow, setIsShow] = useState(false);
  const [titleAOP, setTitleAOP] = useState(title);
  const [
    shouldDisplayLinkingAccountModal,
    setShouldDisplayLinkingAccountModal,
  ] = useState(shouldDisplayLinkingAccountHelpButton);
  const displayLinkingAccountHelp = () => setIsShow(true);
  const handleCloseLinkingAccountHelp = () => setIsShow(false);

  useEffect(() => {
    if (authToken) {
      const frame = document.createElement('script');
      frame.src = fundingUrl;
      frame.type = 'text/javascript';
      frame.setAttribute('id', 'fundingIframe');
      frame.setAttribute('data-id', 'aop-app');
      frame.onload = postAccessTokenToTangoIframe;
      document.getElementById('appDepositFunds').appendChild(frame);
    }
  }, []);

  function postAccessTokenToTangoIframe() {
    const iframe = document.getElementById('inlineFrame');
    const data = { accessToken: authToken };

    if (iframe) {
      iframe.addEventListener('load', () => {
        const iframeDocument =
          iframe.contentWindow ||
          iframe.contentDocument ||
          iframe.contentDocument;
        iframeDocument.postMessage(data, '*');
      });
    }
  }

  window.addEventListener('message', (event) => {
    if (event.origin === config.fundingUrl) {
      if (event.data.pathname === '/external/add-automatic') {
        setShouldDisplayLinkingAccountModal(true);
        setTitleAOP('Add External Account');
      }
    }
  });

  return (
    <div>
      {titleAOP && (
        <div className="col col-sm-12 col-md-12 col-md-offset-2 col-lg-8 col-lg-offset-2 text-center">
          <h3 className="add-external-title">{titleAOP}</h3>
        </div>
      )}

      {shouldDisplayLinkingAccountModal && (
        <>
          <div className="col col-sm-12 col-md-12 col-md-offset-2 col-lg-8 col-lg-offset-2 text-center">
            <btn
              id="additional-options-btn"
              className="btn btn-info slimh_element fitv_element slimv_element reset_capitals"
              onClick={displayLinkingAccountHelp}
              target="_blank"
              rel="noopener noreferrer"
              role="button"
            >
              Problem Linking Your Bank Account?
            </btn>
          </div>
          <LinkAccountModal
            handleClose={handleCloseLinkingAccountHelp}
            isShow={isShow}
            onBack={onBack}
          />
        </>
      )}

      <div className="col col-md-12 col-lg-8 col-lg-offset-2 text-center">
        {
          authToken &&
          <iframe
            id="inlineFrame"
            title="Tango iFrame"
            width="300"
            height="200"
            src={fundingUrl}
          />
        }
      </div>
      {(config.inlineFunding === 'V2' || config.inlineFunding === 'V3') && (
        <div className="row">
          <InlineFundingNavigation
            backButtonId="funding-back-btn"
            onBack={onBack}
          />
        </div>
      )}
    </div>
  );
};

Funding.propTypes = {
  fundingUrl: PropTypes.string,
  onBack: PropTypes.func.isRequired,
  shouldDisplayLinkingAccountHelpButton: PropTypes.bool,
  title: PropTypes.string,
  authToken: PropTypes.string,
};

export default Funding;
