import React from 'react';
import PropTypes from 'prop-types';
import DropDownList from '../../DropDownList';
import TextBox from '../../TextBox';

const Address = (props) => {
  const {
    countryIsReadonly,
    regionIsReadonly,
    countries,
    regions,
  } = props;

  return (
    <div id="address" className="col col-md-12">
      <DropDownList
        fieldName="country"
        className="form-control"
        label="Country"
        options={countries}
        disabled={countryIsReadonly}
      />
      <TextBox
        name="address1"
        className="form-control"
        label="Street Address 1"
        maxLength={40}
        allowSpecialCharacters
      />
      <TextBox
        name="address2"
        className="form-control"
        label="Street Address 2"
        maxLength={40}
        allowSpecialCharacters
      />
      <TextBox
        name="city"
        className="form-control"
        label="City"
        maxLength={30}
        allowSpecialCharacters
      />
      <div className="row">
        <div className="col col-md-6 col-xs-7">
          {regions && regions.length > 1 &&
            <DropDownList
              fieldName="region"
              className="form-control"
              label="State or Province"
              options={regions}
              disabled={regionIsReadonly}
            />
          }
        </div>
        <div className="col col-md-6 col-xs-5">
          <TextBox
            name="zip"
            className="form-control"
            labelMobile="Zip/Postal"
            label="Zip or Postal Code"
            maxLength={10}
          />
        </div>
      </div>
    </div>
  );
};

Address.propTypes = {
  countryIsReadonly: PropTypes.bool.isRequired,
  regionIsReadonly: PropTypes.bool.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  regions: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
};

export default Address;

