import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, SubmissionError, change } from 'redux-form';
import { mapFromAddress, mapToAddress } from '../lib/containerHelpers/address';
import { mapFromIdentity, mapToIdentity } from '../lib/containerHelpers/identity';
import { mapCountryOptions } from '../lib/sortFilterMapCountriesRegions';
import * as assetTypes from '../components/AssetClass/assetTypes';
import * as accountTypeActions from '../actions/accountTypeActions';
import * as assetClassActions from '../actions/assetClassActions';
import * as addressActions from '../actions/addressActions';
import * as identityActions from '../actions/identityActions';
import * as registrationActions from '../actions/registrationActions';
import * as countriesActions from '../actions/countriesActions';
import * as contactActions from '../actions/contactActions';
import PersonalInfoForm from '../components/PersonalInfo/PersonalInfoForm';
import PersonalInfoFormValidation from '../validations/PersonalInfoFormValidation';
import optimizeHelper from '../lib/optimizeHelper';
import * as RouteNavigator from './RouteNavigator';
import pushToAnalytics from '../lib/analytics';
import { aopAnalyticsSteps } from '../lib/analyticsHelper';
import { findContactByType, findRegion } from './contactSearchAndFormat';
import { getBrowserOrigin } from '../lib/browser';
import * as userActions from '../actions/userActions';
import Config from '../Config';

const config = new Config();
const REDIRECT_URL = `${config.apiUrl}/auth/tradestation?redirectTo=`;
const ACCOUNT_TYPE_URL = '/account-type';

const submitAddress = (values, props) => {
  const address = mapToAddress.map(values);
  const submit = props.actions.upsertAddress;
  return submit(address, props.applicationId, props.authToken);
};

const submitIdentity = (values, props) => {
  const identity = mapToIdentity.map(values);
  const submit = props.actions.upsertIdentity;
  return submit(identity, props.applicationId, props.authToken);
};

const isDuplicateIdentity = (codes) => {
  let result = false;
  if (codes && codes.length > 0) {
    for (let i = 0; i < codes.length; i += 1) {
      if (codes[i].includes('custom.Identity already exists') || codes[i].includes('Identity already exists')) {
        result = true;
      }
    }
  }

  return result;
};

export class PersonalInfoFormContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showExistingAccountModal: false,
      showExitModal: false,
    };

    this.handleExitModelOn = this.handleExitModelOn.bind(this);
    this.handleExitModelOff = this.handleExitModelOff.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCopyFromPrimary = this.handleCopyFromPrimary.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    const {
      actions,
      applicationId,
      authToken,
      countries,
      contact,
      address,
      identity,
      registration,
      accountType,
      assetClass,
    } = this.props;

    window.scrollTo(0, 0);

    if (!countries || !countries.length > 0) actions.fetchCountries();
    if (!contact) actions.fetchContact(applicationId, authToken);
    if (!address) actions.fetchAddress(applicationId, authToken);
    if (!identity) actions.fetchIdentity(applicationId, authToken);
    if (!registration || !accountType || !assetClass) {
      Promise.all([
        actions.fetchRegistration(applicationId, authToken),
        actions.fetchAccountType(applicationId, authToken),
        actions.fetchAssetClass(applicationId, authToken),
      ]).then(() => {
        pushToAnalytics(
          aopAnalyticsSteps.PERSONAL_INFO.name,
          { applicationId, authToken, registration, accountType, assetClass },
        );
      });
    } else {
      pushToAnalytics(
        aopAnalyticsSteps.PERSONAL_INFO.name,
        { applicationId, authToken, registration, accountType, assetClass },
      );
    }

    optimizeHelper.notify();
  }

  componentWillUnmount() {
    if (this.state.showExistingAccountModal && config.duplicateRemediationEnabled) {
      this.handleLogout();
    }
  }

  handleLogout() {
    if (this.props.authToken) {
      this.props.actions.userLogout(this.props.authToken)
      .then(() => {
        const redirectTo = getBrowserOrigin();
        window.location = encodeURI(REDIRECT_URL.concat(redirectTo).concat(ACCOUNT_TYPE_URL));
      })
      .catch((error) => { throw new SubmissionError({ _error: error.message }); });
    }
  }

  handleCopyFromPrimary(event) {
    let address = {};
    const {
      isJointApplication,
      isCryptoApplication,
      primaryContact,
      jointContact,
      countries,
      addresses,
      dispatch,
    } = this.props;

    const meetsCryptoJointCriteria = (isCryptoApplication && isJointApplication);

    if (event) {
      if (event.target.id.match(/residential/gi)) {
        address = Object.assign({}, addresses[0].residential, { copyFromPrimary: true });
        address.sameAddress = !!addresses[1].residential.sameAddress;
        if (meetsCryptoJointCriteria) {
          const regionJoint = findRegion(
            primaryContact.countryOfResidence,
            jointContact.regionOfResidence,
            countries,
          );
          address.sameAddress = false;
          address.region = regionJoint.regionCode;
          address.regionName = regionJoint.region;
          address.copyFromPrimary = false;
        }
        dispatch(change('personal-info', 'addresses[1].residential', address));
      }
      if (event.target.id.match(/mailing/gi)) {
        address = Object.assign({}, addresses[0].mailing, { copyFromPrimary: true });
        delete address.sameAddress;
        dispatch(change('personal-info', 'addresses[1].mailing', address));
      }
    }
  }

  handleError(error) {
    if (error.response && error.response.status === 422) {
      return error.response.json()
        .then((json) => {
          let errorMessage = json.error.message;
          const detailsMessage = json.error.details && json.error.details.messages ?
          json.error.details.messages : null;

          if (detailsMessage) {
            if ('identificationSSN' in detailsMessage) {
              errorMessage = 'Invalid Social Security Number or Tax ID. Please try again.';
            }
          }

          if (!config.duplicateRemediationEnabled) {
            if (json.error.message.indexOf('Identity already exists') > -1) {
              errorMessage = 'An account with this identity already exists. Please login to continue';
              this.setState({ showExistingAccountModal: true });
            }
          }

          if (config.duplicateRemediationEnabled) {
            const codes = json.error.details && json.error.details.codes ?
            Object.values(json.error.details.codes) : null;
            const isDuplicate = isDuplicateIdentity(codes) ||
            json.error.message.indexOf('Identity already exists') > -1;
            if (isDuplicate) {
              errorMessage = 'An account with this identity already exists. Please login to continue';
              this.setState({ showExistingAccountModal: true });
            }
          }

          throw new SubmissionError({ _error: errorMessage });
        });
    } else if (error.response && error.response.status === 500) {
      return error.response.json()
        .then((json) => {
          let errorMessage = json.error.message;
          let detailsMessage = json.error.details.length > 0 ?
            json.error.details[0].message : null;

          if (detailsMessage) {
            if (detailsMessage.indexOf('identificationSSN') > -1) {
              detailsMessage = 'Invalid Social Security Number or Tax ID. Please try again.';
            }
          }

          if (config.duplicateRemediationEnabled && json.error.details.length > 0) {
            const codes = json.error.details[0].details ? Object.values(json.error.details[0].details.codes) : null;
            const message = json.error.details[0].message;
            const isDuplicate = isDuplicateIdentity(codes) || message === 'Identity already exists';
            if (isDuplicate) {
              detailsMessage = 'An account with this identity already exists. Please login to continue';
              this.setState({ showExistingAccountModal: true });
            }
          }

          errorMessage = detailsMessage || errorMessage;

          throw new SubmissionError({ _error: errorMessage });
        });
    }

    throw new SubmissionError({ _error: error.message });
  }

  handleSubmit(values) {
    this.setState({ showExistingAccountModal: false });

    return submitIdentity(values, this.props)
      .then(() => submitAddress(values, this.props))
      .then(() => {
        RouteNavigator.push('/employment');
      })
      .catch(this.handleError);
  }

  // eslint-disable-next-line
  handleBack() {
    RouteNavigator.push('/account-type');
  }

  handleExitModelOn() {
    this.setState({ showExitModal: true });
  }

  handleExitModelOff() {
    this.setState({ showExitModal: false });
  }

  render() {
    return (
      <PersonalInfoForm
        {...this.props}
        showExistingAccountModal={this.state.showExistingAccountModal}
        handleCopyFromPrimary={this.handleCopyFromPrimary}
        onSubmit={this.handleSubmit}
        onBack={this.handleBack}
        showExitModal={this.state.showExitModal}
        onExitModalOn={this.handleExitModelOn}
        onExitModalOff={this.handleExitModelOff}
      />
    );
  }
}

PersonalInfoFormContainer.propTypes = {
  applicationId: PropTypes.string,
  authToken: PropTypes.string,
  accountType: PropTypes.shape(),
  address: PropTypes.shape(),
  contact: PropTypes.shape(),
  registration: PropTypes.shape(),
  identity: PropTypes.shape(),
  addresses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  identities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({
    country: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
  })),
  primaryContact: PropTypes.shape(),
  jointContact: PropTypes.shape(),
  actions: PropTypes.shape({
    fetchAccountType: PropTypes.func.isRequired,
    fetchAddress: PropTypes.func.isRequired,
    fetchCountries: PropTypes.func.isRequired,
    fetchContact: PropTypes.func.isRequired,
    upsertAddress: PropTypes.func.isRequired,
    fetchRegistration: PropTypes.func.isRequired,
    fetchIdentity: PropTypes.func.isRequired,
    upsertIdentity: PropTypes.func.isRequired,
    fetchAssetClass: PropTypes.func.isRequired,
    userLogout: PropTypes.func.isRequired,
  }).isRequired,
  dispatch: PropTypes.func,
  assetClass: PropTypes.shape(),
  isJointApplication: PropTypes.bool,
  isCryptoApplication: PropTypes.bool,
};

export function mapStateToProps(state) {
  if (!state) return null;

  const { isReturned, isJoint, isEntity, isCrypto } = {
    isReturned: state.application && state.application.status
      ? state.application.status.indexOf('returned') > -1
      : false,
    isJoint: state.accountType && state.accountType.accountType
      ? state.accountType.accountType.indexOf('joint') > -1
      : false,
    isEntity: state.accountType && state.accountType.accountType
      ? state.accountType.accountType.indexOf('entity') > -1
      : false,
    isCrypto: state.accountType && state.accountType.assetTypes
      ? state.accountType.assetTypes.includes(assetTypes.ASSET_CLASS_TYPES_CRYPTO) ||
      state.accountType.assetTypes.includes(assetTypes.ASSET_CLASS_TYPES_CRYPTO_WAITLIST)
      : false,
  };

  const identityProps = mapFromIdentity.props(state, isJoint);
  const addressProps = mapFromAddress.props(state, isJoint, isCrypto);

  const initialValues = { ...identityProps.initialValues, ...addressProps.initialValues };
  const primaryContact = findContactByType('primary', state.contact);
  const jointContact = findContactByType('joint', state.contact);

  return {
    applicationId: state.applicationId,
    authToken: state.authToken,

    isReturnedApplication: isReturned,
    isJointApplication: isJoint,
    isEntityApplication: isEntity,
    isCryptoApplication: isCrypto,

    initialValues,

    // identity
    identityCountries: mapCountryOptions(identityProps.countries),
    identities: identityProps.identities || [],
    identityExists: !!identityProps.identityExists,
    regions: identityProps.regions,
    months: identityProps.months,
    days: identityProps.days,
    yearsBirthdate: identityProps.yearsBirthdate,
    yearsExpirationDate: identityProps.yearsExpirationDate,
    showExpirationDate: identityProps.showExpirationDate,
    showIdentificationSSN: identityProps.showIdentificationSSN,
    showIdentificationNumber: identityProps.showIdentificationNumber,
    showIdentificationRegion: identityProps.showIdentificationRegion,
    identificationNumberLabel: identityProps.identificationNumberLabel,
    identificationOptions: identityProps.identificationOptions,
    isExistingCustomer: identityProps.isExistingCustomer,
    isFieldEnabled: identityProps.isFieldEnabled,

    // address
    addressCountries: mapCountryOptions(addressProps.countries),
    addresses: addressProps.addresses || [],
    addressExists: !!addressProps.addressExists,
    countryMailing: addressProps.countryMailing,
    countryResidential: addressProps.countryResidential,
    regionsMailing: addressProps.regionsMailing,
    regionsResidential: addressProps.regionsResidential,
    isChinaShandong: addressProps.isChinaShandong,
    isDomestic: addressProps.isDomestic,
    showCopyFromPrimary: addressProps.showCopyFromPrimary,
    showDoNotContact: addressProps.showDoNotContact,
    showMailingAddress: addressProps.showMailingAddress,
    showNonProAddressToolTip: addressProps.showNonProAddressToolTip,

    registration: state.registration,
    accountType: state.accountType,
    assetClass: state.assetClass,
    primaryContact,
    jointContact,
    countries: state.countries,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({},
      accountTypeActions,
      addressActions,
      contactActions,
      countriesActions,
      identityActions,
      registrationActions,
      assetClassActions,
      userActions,
    ), dispatch),
  };
}

export function scrollToFirstError(errors) {
  let errs = [];
  if (errors && errors.identities) {
    errors.identities.forEach((e) => {
      errs = Object.keys(e);
    });
  }

  if (errs && errs.length > 5) {
    const el = document.querySelector(`[name="identities[0].${errs[0]}"]`);
    if (el) {
      const top = (el.getBoundingClientRect().top + document.documentElement.scrollTop) - 100;
      window.scrollTo({ top, behavior: 'smooth' });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  enableReinitialize: true,
  form: 'personal-info',
  validate: PersonalInfoFormValidation,
  onSubmitFail: scrollToFirstError,
})(PersonalInfoFormContainer));

