// Country Codes
export const CHINA = 'CN';
export const CANADA = 'CA';
export const PUERTO_RICO = 'PR';
export const INDIA = 'IN';

// Region Codes
export const SHANDONG = '37';
export const ONTARIO = 'ON';

// Contact Types
export const PRIMARY = 'primary';
export const JOINT = 'joint';

// Options Trading Sliders
export const riskToleranceMarks = {
  0: 'Income',
  50: 'Growth',
  100: 'Speculation',
};

export const optionStrategiesMarks = {
  0: '1',
  25: '2',
  50: '3',
  75: '4',
  100: '5',
};

export const accountAssetClassTitle = {
  crypto: 'Crypto Account',
  equities: ' Equities Account',
  futures: 'Futures Account',
  futuresoptions: 'Futures Options Account',
};

export const accountAssetClassTitleV2 = {
  crypto: 'Crypto',
  equities: ' Equities',
  futures: 'Futures',
  futuresoptions: 'Futures Options',
};

export const accountTypeTitle = {
  individual: 'Individual',
  jointrightsofsurvivorship: 'Joint Rights of Survivorship',
  jointtenantsincommon: 'Joint Tenants In Common',
  iratraditional: 'IRA Traditional',
  iraroth: 'IRA ROTH',
  irasep: 'IRA SEP',
  irasimple: 'IRA Simple',
};

/**
   * Supports:
   * 9 digits format:  “330293439”
   * 5 digit format:    “33029"
   * 10 digit format: “33029-3439”
   */
 export const domesticZipCodePattern = /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/;

/**
  * Supports:
  * Uppercase letters:  “33029LD”
  * Lowercase letters:    “33029ef"
  * Spaces and Hyphen-minus: “33029-34 39”
*/
 export const internationalZipCodePattern = /^[a-zA-Z0-9- ]+$/;

 /**
  * Supports:
  * Match a char that is not part of the char code set from 32-126 and 192-255
  * Set of chars from 192-255: ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýÿ
*/
export const specialCharsPattern = /[^\x20-\x7E\xC0-\xFF]+/g;

 /**
  * Supports:
  * Match a char that is not part of the char code set from32-126
  * Set of chars from 32-126:
  *  !"#$%&'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\]^_`abcdefghijklmnopqrstuvwxyz{|}~
*/
export const nonPrintableCharsPattern = /[^\x20-\x7E]+/g;

