import React from 'react';
import PropTypes from 'prop-types';
import InlineFundingNavigation from '../InlineFundingNavigation';
import Config from '../../Config';
import AcatFrame from './AcatFrame';

const config = new Config();

const ACATPage = (props) => {
  const { onBack, authToken, useAcatExternalSite, acatAppUrl } = props;
  return (
    <div className="acat-container">
      {useAcatExternalSite ? (
        <AcatFrame acatAppUrl={acatAppUrl} authToken={authToken} />
      ) : (
        <div className="col col-sm-12 col-md-12 col-md-offset-2 col-lg-8 col-lg-offset-2 text-center">
          <h3 className="m-2 acat-title">Account Transfer (ACAT)</h3>
          <div className="col col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <p className="acat-text acat-title-text">
              Transfer cash and most securities electronically from an account
              at another financial institution to a TradeStation Securities
              account (including IRA accounts).
            </p>
          </div>
          <div className="col col-xs-12 col-sm-12 col-sm-offset-2 col-md-12 col-lg-12 pl-0 m-1">
            <div className="col col-xs-2 col-sm-2 col-md-2 col-lg-2 pr-0">
              <h6 className="acat-text pr-0 mt-3 step">Step 1:</h6>
            </div>
            <div className="col col-xs-10 col-sm-10 col-md-10 col-lg-10">
              <p className="acat-text step-text">
                Complete and sign the Account transfer form:
              </p>

              <button
                type="submit"
                className="btn p-0 inline-btn do_capitalize download-form-btn"
              >
                <a
                  href={config.accountTransferFormUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download Form
                </a>
              </button>
            </div>
          </div>
          <div className="col col-xs-12 col-sm-12 col-sm-offset-2 col-md-12 col-lg-12 pl-0 m-1">
            <div className="col col-xs-2 col-sm-2 col-md-2 col-lg-2 pr-0">
              <h6 className="acat-text pr-0 mt-3 step">Step 2:</h6>
            </div>
            <div className="col col-xs-10 col-sm-10 col-md-10 col-lg-10">
              <p className="acat-text step-text">
                Include a copy of your most recent statement (this must be
                included in order to complete the transfer)
              </p>
            </div>
          </div>
          <div className="col col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 m-1">
            <div className="col col-xs-2 col-sm-10 col-sm-2 col-md-2 col-lg-2 pr-0">
              <h6 className="acat-text pr-0 mt-3 step">Step 3:</h6>
            </div>
            <div className="col col-xs-10 col-sm-10 col-md-10 col-lg-10">
              <p className="acat-text step-text email">
                Email both of the above documents to:
                <p className="address">
                  &nbsp;
                  <a
                    className="email-link"
                    href="mailto:clientservice@tradestation.com"
                  >
                    clientservice@tradestation.com
                  </a>
                </p>
              </p>
            </div>
          </div>
          <div className="col col-xs-12 col-md-12 col-lg-12 pl-0 m-1">
            <div className="col col-xs-2 col-sm-2 col-md-2 col-lg-2 pr-0" />
            <div className="col col-xs-10 col-sm-10 col-md-10 col-lg-10">
              <p className="acat-text step-text">
                Alternatively, you may submit the documents by mail:
              </p>
              <p className="acat-text step-text address">
                TradeStation Securities, Inc.
                <br />
                8050 SW 10th Street, Suite 2000 <br />
                Plantation, FL 33324
                <br />
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <InlineFundingNavigation backButtonId="acat-back-btn" onBack={onBack} backLabel="Transfer Methods" />
      </div>
    </div>
  );
};

ACATPage.propTypes = {
  onBack: PropTypes.func.isRequired,
  authToken: PropTypes.string.isRequired,
  useAcatExternalSite: PropTypes.bool,
  acatAppUrl: PropTypes.string.isRequired,
};

export default ACATPage;
